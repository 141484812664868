import React from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import {
  Button,
  ButtonGroup,
  NonIdealState,
  Spinner,
  Tag,
  Text,
} from "@blueprintjs/core";
import { debounce } from "../util";

const genomicKeywordArray = [
  { target: "dnaCount", lower: "dna", proper: "DNA" },
  { target: "rnaCount", lower: "rna", proper: "RNA" },
  { target: "hlaCount", lower: "hla", proper: "HLA" },
  { target: "tmbCount", lower: "tmb", proper: "TMB" },
];
const outcomeKeywordArray = [
  { target: "survivalCount", lower: "survival", proper: "Survival" },
  { target: "osCount", lower: "os", proper: "OS" },
  { target: "pfsCount", lower: "pfs", proper: "PFS" },
  { target: "responseCount", lower: "response", proper: "Response" },
  { target: "recistCount", lower: "recist", proper: "RECIST" },
];
const genomicKeywordSet = new Set(genomicKeywordArray.map((k) => k.lower));
const outcomeKeywordSet = new Set(outcomeKeywordArray.map((k) => k.lower));

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      tags: [],
    };
    this.handleChange = debounce(this.handleChange, 350);
  }

  static getDerivedStateFromProps(props, state) {
    const { data } = props;
    const { items, tags } = state;
    return {
      items: Array.from(data.entries()).map(([datasetName, samples], i) => {
        const matchingItem = items.filter((item) => item.name === datasetName);
        return {
          name: datasetName,
          samples: samples.map((s) => ({
            ...s,
            cancerType: s.cancerType.toLowerCase(),
          })),
          dnaCount: samples.filter((s) => s.hasDNA).length,
          tmbCount: samples.filter((s) => s.hasTMB).length,
          rnaCount: samples.filter((s) => s.hasRNA).length,
          hlaCount: samples.filter((s) => s.hasHLA).length,
          survivalCount: samples.filter((s) => s.hasSurvival).length,
          osCount: samples.filter((s) => s.hasOS).length,
          pfsCount: samples.filter((s) => s.hasPFS).length,
          responseCount: samples.filter((s) => s.hasResponse).length,
          recistCount: samples.filter((s) => s.hasRECIST).length,
          cancerTypes: Array.from(new Set(samples.map((s) => s.cancerType))),
          matchingSampleIds:
            matchingItem.length === 1
              ? matchingItem[0].matchingSampleIds
              : new Set(samples.map((s) => s.sampleId)),
          order: i,
        };
      }),
      tags,
    };
  }

  handleChange(query) {
    let { items } = this.state;
    const newTags = new Set([]);

    const cancerTypes = new Set(
      items
        .map((item) =>
          item.cancerTypes.map((cancerType) => cancerType.toLowerCase())
        )
        .flat()
    );

    // Clear points for previous query
    items = items.map((item) => ({
      ...item,
      matchingSampleIds: new Set(item.samples.map((s) => s.sampleId)),
    }));

    // Extract valid keywords
    const keywords = query
      .split(" ")
      .map((k) => k.toLowerCase())
      .filter((k) => k.length > 0);

    const matchSampleIds = (item, filter) =>
      d3.intersection(
        item.matchingSampleIds,
        item.samples.filter((s) => s[filter]).map((s) => s.sampleId)
      );

    keywords.forEach((keyword, _unused) => {
      if (genomicKeywordSet.has(keyword)) {
        newTags.add({ keyword, intent: "primary" });
        items = items.map((item) => {
          if (keyword === "dna") {
            if (item.dnaCount) {
              return {
                ...item,
                matchingSampleIds: matchSampleIds(item, "hasDNA"),
              };
            }
            return {
              ...item,
              matchingSampleIds: new Set(),
            };
          }
          if (keyword === "tmb") {
            if (item.tmbCount) {
              return {
                ...item,
                matchingSampleIds: matchSampleIds(item, "hasTMB"),
              };
            }
            return {
              ...item,
              matchingSampleIds: new Set(),
            };
          }
          if (keyword === "rna") {
            if (item.rnaCount) {
              return {
                ...item,
                matchingSampleIds: matchSampleIds(item, "hasRNA"),
              };
            }
            return {
              ...item,
              matchingSampleIds: new Set(),
            };
          }
          if (keyword === "hla") {
            if (item.hlaCount) {
              return {
                ...item,
                matchingSampleIds: matchSampleIds(item, "hasHLA"),
              };
            }
            return {
              ...item,
              matchingSampleIds: new Set(),
            };
          }
          return item;
        });
      } else if (outcomeKeywordSet.has(keyword)) {
        newTags.add({ keyword, intent: "success" });
        items = items.map((item) => {
          if (keyword === "survival") {
            if (item.survivalCount) {
              return {
                ...item,
                matchingSampleIds: matchSampleIds(item, "hasSurvival"),
              };
            }
            return {
              ...item,
              matchingSampleIds: new Set(),
            };
          }
          if (keyword === "os") {
            if (item.osCount) {
              return {
                ...item,
                matchingSampleIds: matchSampleIds(item, "hasOS"),
              };
            }
            return {
              ...item,
              matchingSampleIds: new Set(),
            };
          }
          if (keyword === "pfs") {
            if (item.pfsCount) {
              return {
                ...item,
                matchingSampleIds: matchSampleIds(item, "hasPFS"),
              };
            }
            return {
              ...item,
              matchingSampleIds: new Set(),
            };
          }
          if (keyword === "response") {
            if (item.responseCount) {
              return {
                ...item,
                matchingSampleIds: matchSampleIds(item, "hasResponse"),
              };
            }
            return {
              ...item,
              matchingSampleIds: new Set(),
            };
          }
          if (keyword === "recist") {
            if (item.recistCount) {
              return {
                ...item,
                matchingSampleIds: matchSampleIds(item, "hasRECIST"),
              };
            }
            return {
              ...item,
              matchingSampleIds: new Set(),
            };
          }
          return item;
        });
      } else if (cancerTypes.has(keyword)) {
        newTags.add({ keyword, intent: "danger" });
        items = items.map((item) => {
          if (item.cancerTypes.map((c) => c.toLowerCase()).includes(keyword)) {
            return {
              ...item,
              matchingSampleIds: d3.intersection(
                item.matchingSampleIds,
                item.samples
                  .filter((s) => s.cancerType === keyword)
                  .map((s) => s.sampleId)
              ),
            };
          }
          return {
            ...item,
            matchingSampleIds: new Set(),
          };
        });
      } else {
        newTags.add({ keyword, intent: "none" });
      }
    });

    this.setState({
      items,
      tags: Array.from(newTags),
    });
  }

  render() {
    const { items, tags } = this.state;
    const { published, isFetching, toggleVisibility } = this.props;
    const tagSet = new Set(tags.map((t) => t.keyword));

    let itemsOrdered;
    if (tags.length > 0) {
      itemsOrdered = items
        .sort((a, b) => a.order - b.order)
        .sort((a, b) => b.matchingSampleIds.size - a.matchingSampleIds.size);
    } else {
      itemsOrdered = items.sort((a, b) => a.order - b.order);
    }

    const cancerTypes = new Set(
      items
        .map((item) =>
          item.cancerTypes.map((cancerType) => cancerType.toLowerCase())
        )
        .flat()
    );

    return (
      <div className="search-container">
        <input
          className="bp3-input"
          autoComplete="off"
          type="text"
          id="name"
          name="name"
          inputMode="search"
          onChange={(e) => this.handleChange(e.target.value)}
          style={{ width: "100%", marginBottom: "8px" }}
        />
        <div className="search-tag-container">
          {genomicKeywordArray.map((w) => (
            <Tag key={w.lower} intent="primary" minimal={!tagSet.has(w.lower)}>
              {w.proper}
            </Tag>
          ))}
          {outcomeKeywordArray.map((w) => (
            <Tag key={w.lower} intent="success" minimal={!tagSet.has(w.lower)}>
              {w.proper}
            </Tag>
          ))}
          <Tag
            intent="danger"
            minimal={!d3.intersection(tagSet, cancerTypes).size > 0}
          >
            *Cancer Types*
          </Tag>
        </div>
        <div className="cohort-container">
          {isFetching ? (
            <Spinner intent="primary" size={50} />
          ) : (
            itemsOrdered
              .filter((item) => item.matchingSampleIds.size > 0)
              .map((item) => (
                <div key={item.name} className="cohort">
                  <div className="left">
                    <ButtonGroup minimal>
                      <Button
                        icon={
                          published.get(item.name) &&
                          !published.get(item.name).isHidden
                            ? "eye-on"
                            : "eye-off"
                        }
                        intent={
                          published.get(item.name) &&
                          !published.get(item.name).isHidden
                            ? "success"
                            : "none"
                        }
                        onClick={() => toggleVisibility(item.name)}
                        small
                      />
                      <Tag round minimal>
                        {item.matchingSampleIds.size}
                      </Tag>
                    </ButtonGroup>
                  </div>
                  <div className="right">
                    <Text className="name">{item.name}</Text>
                    <div className="search-cohort-tag-container">
                      {genomicKeywordArray.map((w) =>
                        item[w.target] > 0 ? (
                          <Tag
                            key={w.lower}
                            intent="primary"
                            minimal={!tagSet.has(w.lower)}
                          >
                            {w.proper}
                          </Tag>
                        ) : (
                          <span key={w.lower} />
                        )
                      )}
                      {outcomeKeywordArray.map((w) =>
                        item[w.target] > 0 ? (
                          <Tag
                            key={w.lower}
                            intent="success"
                            minimal={!tagSet.has(w.lower)}
                          >
                            {w.proper}
                          </Tag>
                        ) : (
                          <span key={w.lower} />
                        )
                      )}
                      {item.cancerTypes.map((type) => (
                        <Tag
                          key={type}
                          intent="danger"
                          minimal={!tagSet.has(type.toLowerCase())}
                        >
                          {type}
                        </Tag>
                      ))}
                    </div>
                  </div>
                </div>
              ))
          )}
          {!isFetching &&
          tagSet.size > 0 &&
          itemsOrdered.filter((item) => item.matchingSampleIds.size > 0)
            .length === 0 ? (
            <NonIdealState
              icon="search"
              title="No results"
              description="There are no samples that fit this criteria."
            />
          ) : (
            <div />
          )}
        </div>
      </div>
    );
  }
}

Search.propTypes = {
  data: PropTypes.object.isRequired,
  published: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  toggleVisibility: PropTypes.func.isRequired,
};

export default Search;
