import { defaultMutation } from "../defaultStates";

const modifyGeneList = (state, action) => {
  switch (action.type) {
    case "ADD_MUTATION_GENE":
      return state.geneList.concat([
        { id: state.geneList.length, ...action.gene },
      ]);
    case "ADD_MULTIPLE_MUTATION_GENES":
      return state.geneList.concat(
        action.geneList.map((gene, i) => ({
          id: state.geneList.length + i,
          ...gene,
        }))
      );
    case "REMOVE_MUTATION_GENE":
      return state.geneList.filter((gene) => gene.id !== action.gene.id);
    case "CLEAR_MUTATION_GENES":
      return [];
    default:
      return state.geneList;
  }
};

const modifyGeneSet = (state, action) => {
  switch (action.type) {
    case "SELECT_MUTATION_GENE_SET":
      return action.geneSet;
    default:
      return state.geneSet;
  }
};

const modifySortBy = (state, action) => {
  switch (action.type) {
    case "SELECT_MUTATION_SORT_BY":
      return action.sortBy;
    case "REMOVE_MUTATION_GENE":
      return state.sortBy === "gene" && state.selectedGene === action.gene.id
        ? "most-frequent-variant"
        : state.sortBy;
    case "CLEAR_MUTATION_GENES":
      return state.sortBy === "gene" ? "most-frequent-variant" : state.sortBy;
    case "REMOVE_MUTATION_DATA_FIELD":
      return state.sortBy === "dataField" &&
        state.selectedDataField === action.dataField
        ? "most-frequent-variant"
        : state.sortBy;
    case "CLEAR_MUTATION_DATA_FIELDS":
      return state.sortBy === "dataField"
        ? "most-frequent-variant"
        : state.sortBy;
    default:
      return state.sortBy;
  }
};

const modifySelectedGene = (state, action) => {
  switch (action.type) {
    case "REMOVE_MUTATION_GENE":
      return state.selectedGene === action.gene
        ? { type: "" }
        : state.selectedGene;
    case "CLEAR_MUTATION_GENES":
      return { type: "" };
    case "SELECT_MUTATION_SORT_BY_GENE":
      return action.gene;
    default:
      return state.selectedGene;
  }
};

const modifySelectedDataField = (state, action) => {
  switch (action.type) {
    case "REMOVE_MUTATION_DATA_FIELD":
      return state.selectedDataField === action.dataField
        ? ""
        : state.selectedDataField;
    case "CLEAR_MUTATION_DATA_FIELDS":
      return "";
    case "SELECT_MUTATION_SORT_BY_DATA_FIELD":
      return action.dataField;
    default:
      return state.selectedDataField;
  }
};

const modifyDataFields = (state, action) => {
  switch (action.type) {
    case "ADD_MUTATION_DATA_FIELD":
      return state.dataFields.concat(action.dataField);
    case "REMOVE_MUTATION_DATA_FIELD":
      return state.dataFields.filter((field) => field !== action.dataField);
    case "CLEAR_MUTATION_DATA_FIELDS":
      return [];
    default:
      return state.dataFields;
  }
};

const modifyOptions = (state, action) => {
  switch (action.type) {
    case "SELECT_MUTATION_INTERACTION":
      return { ...state.options, interaction: action.interaction };
    default:
      return state.options;
  }
};

const mutation = (state = defaultMutation, action) => ({
  geneList: modifyGeneList(state, action),
  geneSet: modifyGeneSet(state, action),
  sortBy: modifySortBy(state, action),
  selectedGene: modifySelectedGene(state, action),
  selectedDataField: modifySelectedDataField(state, action),
  dataFields: modifyDataFields(state, action),
  options: modifyOptions(state, action),
});

export default mutation;
