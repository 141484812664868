import { connect } from "react-redux";
import { selectMutationInteraction } from "../../actions";
import MutationInteractionRadio from "../../components/mutation/InteractionRadio";

const mapStateToProps = (state) => ({
  interaction: state.mutation.present.options.interaction,
});

const mapDispatchToProps = (dispatch) => ({
  selectInteraction: (interaction) =>
    dispatch(selectMutationInteraction(interaction)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MutationInteractionRadio);
