import React from "react";
import PropTypes from "prop-types";
import { Button, ButtonGroup, Icon, Text } from "@blueprintjs/core";
import MappingPopover from "../../containers/cohorts/MappingPopover";
import GenomicCountsBar from "./GenomicCountsBar";

const Saved = ({
  id,
  name,
  isSelected,
  counts,
  cancerType,
  toggle,
  highlight,
  hide,
}) => (
  <div id={id} className="published-cohort">
    <div className={isSelected ? "element selected" : "element"}>
      <div className="buttons">
        <ButtonGroup minimal>
          <Button
            icon={isSelected ? "selection" : "circle"}
            onClick={() => toggle(id)}
            small
          />
        </ButtonGroup>
      </div>
      <div className="cohort-body">
        <div className="row title">
          <div className="column-10-12">
            <Text ellipsize>{name}</Text>
          </div>
          <div className="column-2-12">
            <div className="icons-group">
              <MappingPopover id={id} />
              <Icon
                className="icon-as-button"
                icon="maximize"
                onClick={() => highlight(id)}
              />
              <Icon
                className="icon-as-button"
                icon="minimize"
                onClick={() => hide(id)}
                style={{ display: "none" }}
              />
            </div>
          </div>
        </div>
        <div className="row patient-counts">
          <div className="column-9-12">
            <div className="spaced">
              <span>{cancerType}</span>
              <span>
                {counts.visiblePatients} | {counts.visibleSamples}
              </span>
            </div>
          </div>
          <div className="column-3-12">
            <GenomicCountsBar
              counts={{ ...counts, allSamples: counts.visibleSamples }}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

Saved.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  counts: PropTypes.shape({
    visiblePatients: PropTypes.number.isRequired,
    visibleSamples: PropTypes.number.isRequired,
    allPatients: PropTypes.number.isRequired,
    allSamples: PropTypes.number.isRequired,
    dnaAndRnaCount: PropTypes.number.isRequired,
    onlyDnaCount: PropTypes.number.isRequired,
    onlyRnaCount: PropTypes.number.isRequired,
  }).isRequired,
  cancerType: PropTypes.string.isRequired,
  // isFetchingTreatment: PropTypes.bool.isRequired,
  // isFetchingDNACounts: PropTypes.bool.isRequired,
  // isFetchingRNACounts: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  highlight: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
};

export default Saved;
