import * as R from "ramda";
import { connect } from "react-redux";
import {
  getWithDefault,
  matchId,
  uniqueIds,
  assignIdsToCohort,
} from "../../util";
import {
  togglePublished,
  togglePublishedVisibility,
  highlightCohort,
} from "../../actions/cohorts";
import Published from "../../components/cohorts/Published";

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;

  const published = R.mergeLeft(
    matchId(id, state.cohort.present.published),
    matchId(id, state.dataFetch.datasets.data)
  );

  const { url, isSelected } = published;
  const treatmentTable = getWithDefault(
    ["dataFetch", "treatments", "data"],
    state,
    []
  );
  const dnaCountsTable = getWithDefault(
    ["dataFetch", "dnaCounts", "data"],
    state,
    []
  );
  const rnaCountsTable = getWithDefault(
    ["dataFetch", "rnaCounts", "data"],
    state,
    []
  );
  const cancerTypeTable = getWithDefault(
    ["dataFetch", "cancerTypes", "data"],
    state,
    []
  );

  const {
    patientIds: visiblePatientIds,
    sampleIds: visibleSampleIds,
  } = assignIdsToCohort(published, treatmentTable);
  const { patientIds: allPatientIds, sampleIds: allSampleIds } = uniqueIds(
    treatmentTable
  );

  const visibleSampleIdsSet = new Set(visibleSampleIds);
  const { sampleIds: dnaSampleIds } = uniqueIds(
    dnaCountsTable.filter((r) => visibleSampleIdsSet.has(r.sampleId))
  );
  const { sampleIds: rnaSampleIds } = uniqueIds(
    rnaCountsTable.filter((r) => visibleSampleIdsSet.has(r.sampleId))
  );

  const dnaSampleIdSet = new Set(dnaSampleIds);
  const rnaSampleIdSet = new Set(rnaSampleIds);

  const dnaAndRnaCount = visibleSampleIds.filter(
    (_id) => dnaSampleIdSet.has(_id) && rnaSampleIdSet.has(_id)
  ).length;
  const onlyDnaCount = visibleSampleIds.filter(
    (_id) => dnaSampleIdSet.has(_id) && !rnaSampleIdSet.has(_id)
  ).length;
  const onlyRnaCount = visibleSampleIds.filter(
    (_id) => !dnaSampleIdSet.has(_id) && rnaSampleIdSet.has(_id)
  ).length;

  const cancerTypeSet = new Set(
    cancerTypeTable
      .filter((d) => visibleSampleIdsSet.has(d.sampleId))
      .map((d) => d.cancerType)
  );

  return {
    id,
    name: id,
    url,
    isSelected,
    counts: {
      allPatients: allPatientIds.length,
      allSamples: allSampleIds.length,
      visiblePatients: visiblePatientIds.length,
      visibleSamples: visibleSampleIds.length,
      dnaAndRnaCount,
      onlyDnaCount,
      onlyRnaCount,
    },
    cancerType:
      cancerTypeSet.size === 1 ? Array.from(cancerTypeSet)[0] : "Multiple",
    isFetchingTreatment: false,
    isFetchingDNACounts: false,
    isFetchingRNACounts: false,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggle: (id) => dispatch(togglePublished(id)),
  highlight: (id) => dispatch(highlightCohort(id)),
  hide: (name) => dispatch(togglePublishedVisibility(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Published);
