import React from "react";
import PropTypes from "prop-types";
import { Radio, RadioGroup } from "@blueprintjs/core";

const InteractionRadio = ({ interaction, selectInteraction }) => (
  <div>
    <RadioGroup
      onChange={(e) => selectInteraction(e.target.value)}
      selectedValue={interaction}
    >
      <Radio label="Tooltip" value="tooltip" />
      <Radio label="Brush" value="brush" />
    </RadioGroup>
  </div>
);

InteractionRadio.propTypes = {
  interaction: PropTypes.string.isRequired,
  selectInteraction: PropTypes.func.isRequired,
};

export default InteractionRadio;
