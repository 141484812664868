import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";

const transformData = (counts) => {
  // single bars
  if (counts.dnaAndRnaCount === counts.allSamples) {
    return [
      {
        start: 0,
        end: counts.dnaAndRnaCount,
        color: "mediumslateblue",
        type: "single",
      },
    ];
  }
  if (counts.onlyDnaCount === counts.allSamples) {
    return [
      {
        start: 0,
        end: counts.onlyDnaCount,
        color: "dodgerblue",
        type: "single",
      },
    ];
  }
  if (counts.onlyRnaCount === counts.allSamples) {
    return [
      {
        start: 0,
        end: counts.onlyRnaCount,
        color: "salmon",
        type: "single",
      },
    ];
  }
  if (
    counts.dnaAndRnaCount === 0 &&
    counts.onlyDnaCount === 0 &&
    counts.onlyRnaCount === 0
  ) {
    return [
      {
        start: 0,
        end: counts.allSamples,
        color: "azure",
        type: "single",
      },
    ];
  }
  // pairs
  if (
    counts.dnaAndRnaCount > 0 &&
    counts.onlyDnaCount === 0 &&
    counts.onlyRnaCount === 0
  ) {
    return [
      {
        start: 0,
        end: counts.dnaAndRnaCount,
        color: "mediumslateblue",
        type: "left",
      },
      {
        start: counts.dnaAndRnaCount,
        end: counts.allSamples,
        color: "azure",
        type: "right",
      },
    ];
  }
  if (
    counts.dnaAndRnaCount === 0 &&
    counts.onlyDnaCount > 0 &&
    counts.onlyRnaCount === 0
  ) {
    return [
      {
        start: 0,
        end: counts.onlyDnaCount,
        color: "dodgerblue",
        type: "left",
      },
      {
        start: counts.onlyDnaCount,
        end: counts.allSamples,
        color: "azure",
        type: "right",
      },
    ];
  }
  if (
    counts.dnaAndRnaCount === 0 &&
    counts.onlyDnaCount === 0 &&
    counts.onlyRnaCount > 0
  ) {
    return [
      {
        start: 0,
        end: counts.onlyRnaCount,
        color: "salmon",
        type: "left",
      },
      {
        start: counts.onlyRnaCount,
        end: counts.allSamples,
        color: "azure",
        type: "right",
      },
    ];
  }
  // one middle
  if (
    counts.dnaAndRnaCount > 0 &&
    counts.onlyDnaCount > 0 &&
    counts.onlyRnaCount === 0
  ) {
    return [
      {
        start: 0,
        end: counts.onlyDnaCount,
        color: "dodgerblue",
        type: "left",
      },
      {
        start: counts.onlyDnaCount,
        end: counts.onlyDnaCount + counts.dnaAndRnaCount,
        color: "mediumslateblue",
        type: "middle",
      },
      {
        start: counts.dnaAndRnaCount + counts.onlyDnaCount,
        end: counts.allSamples,
        color: "azure",
        type: "right",
      },
    ];
  }
  if (
    counts.dnaAndRnaCount > 0 &&
    counts.onlyDnaCount === 0 &&
    counts.onlyRnaCount > 0
  ) {
    return [
      {
        start: 0,
        end: counts.dnaAndRnaCount,
        color: "mediumslateblue",
        type: "left",
      },
      {
        start: counts.dnaAndRnaCount,
        end: counts.dnaAndRnaCount + counts.onlyRnaCount,
        color: "salmon",
        type: "middle",
      },
      {
        start: counts.dnaAndRnaCount + counts.onlyRnaCount,
        end: counts.allSamples,
        color: "azure",
        type: "right",
      },
    ];
  }
  if (
    counts.dnaAndRnaCount === 0 &&
    counts.onlyDnaCount > 0 &&
    counts.onlyRnaCount > 0
  ) {
    return [
      {
        start: 0,
        end: counts.onlyDnaCount,
        color: "dodgerblue",
        type: "left",
      },
      {
        start: counts.onlyDnaCount,
        end: counts.onlyDnaCount + counts.onlyRnaCount,
        color: "salmon",
        type: "middle",
      },
      {
        start: counts.onlyDnaCount + counts.onlyRnaCount,
        end: counts.allSamples,
        color: "azure",
        type: "right",
      },
    ];
  }
  if (
    counts.dnaAndRnaCount + counts.onlyDnaCount + counts.onlyRnaCount ===
    counts.allSamples
  ) {
    return [
      {
        start: 0,
        end: counts.onlyDnaCount,
        color: "dodgerblue",
        type: "left",
      },
      {
        start: counts.onlyDnaCount,
        end: counts.onlyDnaCount + counts.dnaAndRnaCount,
        color: "mediumslateblue",
        type: "middle",
      },
      {
        start: counts.dnaAndRnaCount + counts.onlyDnaCount,
        end: counts.dnaAndRnaCount + counts.onlyDnaCount + counts.onlyRnaCount,
        color: "salmon",
        type: "right",
      },
    ];
  }
  return [
    {
      start: 0,
      end: counts.onlyDnaCount,
      color: "dodgerblue",
      type: "left",
    },
    {
      start: counts.onlyDnaCount,
      end: counts.onlyDnaCount + counts.dnaAndRnaCount,
      color: "mediumslateblue",
      type: "middle",
    },
    {
      start: counts.dnaAndRnaCount + counts.onlyDnaCount,
      end: counts.dnaAndRnaCount + counts.onlyDnaCount + counts.onlyRnaCount,
      color: "salmon",
      type: "middle",
    },
    {
      start: counts.dnaAndRnaCount + counts.onlyDnaCount + counts.onlyRnaCount,
      end: counts.allSamples,
      color: "azure",
      type: "right",
    },
  ];
};

const GenomicCountsBar = ({ counts }) => {
  const ref = useRef(null);
  useEffect(() => {
    if (counts && ref.current) {
      let svg = d3.select(ref.current); // eslint-disable-line

      svg.selectAll("path").remove();

      const height = 18;
      const width = 66;
      const margin = {
        top: 4,
        bottom: 4,
        left: 16,
        right: 16,
      };

      const bezierOffset = 8;
      const offset = 4;

      const scaleX = d3
        .scaleLinear()
        .domain([0, counts.allSamples])
        .range([margin.left, width - margin.right]);

      const data = transformData(counts);

      data.forEach((d) => {
        const block = d3.path();
        if (d.type === "left") {
          block.moveTo(scaleX(d.start) + offset, margin.top);
          block.lineTo(scaleX(d.end) + offset, margin.top);
          block.lineTo(scaleX(d.end) - offset, height - margin.bottom);
          block.lineTo(scaleX(d.start) - offset, height - margin.bottom);
          block.bezierCurveTo(
            scaleX(d.start) - offset - bezierOffset,
            height - margin.bottom,
            scaleX(d.start) - offset - bezierOffset,
            margin.top,
            scaleX(d.start) - offset,
            margin.top
          );
          block.closePath();
        } else if (d.type === "middle") {
          block.moveTo(scaleX(d.start) + offset, margin.top);
          block.lineTo(scaleX(d.start) - offset, height - margin.bottom);
          block.lineTo(scaleX(d.end) - offset, height - margin.bottom);
          block.lineTo(scaleX(d.end) + offset, margin.top);
          block.closePath();
        } else if (d.type === "right") {
          block.moveTo(scaleX(d.end) + offset, margin.top);
          block.lineTo(scaleX(d.start) + offset, margin.top);
          block.lineTo(scaleX(d.start) - offset, height - margin.bottom);
          block.lineTo(scaleX(d.end) - offset, height - margin.bottom);
          block.lineTo(scaleX(d.end) + offset, height - margin.bottom);
          block.bezierCurveTo(
            scaleX(d.end) + offset + bezierOffset,
            height - margin.bottom,
            scaleX(d.end) + offset + bezierOffset,
            margin.top,
            scaleX(d.end) + offset,
            margin.top
          );
          block.closePath();
        } else {
          // single
          block.moveTo(scaleX(d.end) + offset, margin.top);
          block.lineTo(scaleX(d.start) - offset, margin.top);
          block.bezierCurveTo(
            scaleX(d.start) - offset - bezierOffset,
            margin.top,
            scaleX(d.start) - offset - bezierOffset,
            height - margin.bottom,
            scaleX(d.start) - offset,
            height - margin.bottom
          );
          block.lineTo(scaleX(d.start) - offset, height - margin.bottom);
          block.lineTo(scaleX(d.end) - offset, height - margin.bottom);
          block.lineTo(scaleX(d.end) + offset, height - margin.bottom);
          block.bezierCurveTo(
            scaleX(d.end) + offset + bezierOffset,
            height - margin.bottom,
            scaleX(d.end) + offset + bezierOffset,
            margin.top,
            scaleX(d.end) + offset,
            margin.top
          );
        }
        svg
          .append("path")
          .style("stroke", "black")
          .style("stroke-width", "0.2px")
          .style("fill", d.color)
          .attr("d", block.toString());
      });
    }
  }, [counts]);

  return (
    <div
      id="genomic-counts-bar-container"
      style={{ height: "inherit", width: "100%" }}
    >
      <svg ref={ref} style={{ height: "18px", width: "100%" }} />
    </div>
  );
};

GenomicCountsBar.propTypes = {
  counts: PropTypes.shape({
    allSamples: PropTypes.number.isRequired,
    dnaAndRnaCount: PropTypes.number.isRequired,
    onlyDnaCount: PropTypes.number.isRequired,
    onlyRnaCount: PropTypes.number.isRequired,
  }).isRequired,
};

export default GenomicCountsBar;
