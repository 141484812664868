import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  ControlGroup,
  HTMLSelect,
  Menu,
  MenuDivider,
  MenuItem,
  Tag,
} from "@blueprintjs/core";
import { MultiSelect } from "@blueprintjs/select";
// import LabelWithInfo from "./LabelWithInfo";
import predicate from "../util/genes";

const GeneSelect = ({
  allGenes,
  geneSets,
  geneList,
  selectedGeneSet,
  addGene,
  addMultipleGenes,
  removeGene,
  clearGenes,
  selectGeneSet,
}) => (
  <div className="gene-picker-container">
    <MultiSelect
      itemPredicate={predicate}
      itemListRenderer={({ filteredItems, query, renderItem }) => {
        if (query.length < 2) {
          return (
            <Menu>
              <MenuItem disabled text="Keep typing to see suggestions" />
            </Menu>
          );
        }
        if (filteredItems.length === 0) {
          return (
            <Menu>
              <MenuItem disabled text="No results." />
            </Menu>
          );
        }
        return (
          <div>
            <Menu style={{ maxHeight: "140px", overflow: "scroll" }}>
              {filteredItems.map((item) => renderItem(item))}
            </Menu>
            <Menu>
              <MenuDivider />
              <MenuItem
                icon="add-to-artifact"
                text="Add All Matching Genes"
                labelElement=<Tag>{filteredItems.length}</Tag>
                onClick={() => addMultipleGenes(filteredItems)}
              />
            </Menu>
          </div>
        );
      }}
      itemRenderer={(gene, { handleClick, modifiers }) => (
        <MenuItem
          active={modifiers.active}
          disabled={modifiers.disabled}
          key={gene.type + gene[gene.type]}
          text={gene[gene.type]}
          labelElement={
            gene.type === "symbol" ? (
              <Tag intent="danger">Symbol</Tag>
            ) : gene.type === "entrez" ? (
              <Tag intent="warning">Entrez</Tag>
            ) : (
              <Tag intent="success">Ensembl</Tag>
            )
          }
          onClick={handleClick}
        />
      )}
      items={allGenes}
      itemsEqual={(a, b) => {
        if (a.type === b.type) {
          return a[a.type] === b[b.type];
        }
        return false;
      }}
      onItemSelect={(gene) => addGene(gene)}
      onRemove={(gene) => removeGene(gene)}
      openOnKeyDown
      popoverProps={{
        boundary: "viewport",
        placement: "bottom-start",
        fill: true,
        minimal: true,
        usePortal: true,
      }}
      resetOnSelect
      selectedItems={geneList}
      tagInputProps={{
        rightElement:
          geneList.length > 0 ? (
            <Button icon="cross" minimal onClick={() => clearGenes()} />
          ) : undefined,
      }}
      tagRenderer={(gene) => <Tag>{gene[gene.type]}</Tag>}
    />
    <ControlGroup fill style={{ marginTop: "4px" }}>
      <HTMLSelect
        value={selectedGeneSet}
        options={["Gene Sets"].concat(Object.keys(geneSets))}
        onChange={(e) => selectGeneSet(e.target.value)}
      />
      <Button
        disabled={selectedGeneSet === "" || selectedGeneSet === "Gene Sets"}
        icon="insert"
        intent="primary"
        onClick={() => addMultipleGenes(geneSets[selectedGeneSet].symbols)}
      />
    </ControlGroup>
  </div>
);

// <LabelWithInfo
//   title="Genes"
//   body={[
//     "Use the selection area below to enter the desired gene(s) to appear on the plot.",
//   ]}
// />

GeneSelect.propTypes = {
  allGenes: PropTypes.array.isRequired,
  geneSets: PropTypes.object.isRequired,
  geneList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  selectedGeneSet: PropTypes.string.isRequired,
  addGene: PropTypes.func.isRequired,
  addMultipleGenes: PropTypes.func.isRequired,
  removeGene: PropTypes.func.isRequired,
  clearGenes: PropTypes.func.isRequired,
  selectGeneSet: PropTypes.func.isRequired,
};

export default GeneSelect;
