import React from "react";
import PropTypes from "prop-types";
import { Button, Icon, MenuItem, Tag, Tooltip } from "@blueprintjs/core";
import { MultiSelect } from "@blueprintjs/select";

const groupColor = (group) => {
  switch (group) {
    case "Demographic":
      return "#7D5125";
    case "Clinical":
      return "#C22762";
    case "DNA":
      return "#2458B3";
    case "CIBERSORT":
      return "#238C2C";
    case "ssGSEA":
      return "#BF8C0A";
    default:
      return "gray";
  }
};

const getSelectedField = (allFields, selectedField) => {
  if (allFields === undefined) {
    return undefined;
  }
  return allFields.filter((field) => field.field === selectedField).shift();
};

const selectedFieldReverseLookup = (allFields, selectedFieldName) => {
  if (allFields === undefined) {
    return undefined;
  }
  return allFields.filter((field) => field.name === selectedFieldName).shift();
};

const TopTrackDataFieldPicker = ({
  allFields,
  dataFields,
  tooltip,
  add,
  remove,
  clear,
}) => (
  <div className="data-field-picker-container">
    <Tooltip {...tooltip}>
      <div>
        <MultiSelect
          itemPredicate={(query, item) =>
            query.toLowerCase() ===
              item.name.toLowerCase().slice(0, query.length) ||
            query.toLowerCase() ===
              item.table.toLowerCase().slice(0, query.length) ||
            query.toLowerCase() ===
              item.group.toLowerCase().slice(0, query.length)
          }
          itemRenderer={(field, { handleClick, modifiers }) => {
            if (!modifiers.matchesPredicate) {
              return null;
            }
            const text = `${field.name}`;
            return (
              <MenuItem
                active={modifiers.active}
                label=<div className="data-field-label-tag-container">
                  <Tag
                    style={{
                      backgroundColor:
                        field.table === "Patient" ? "#5642A6" : "#008075",
                    }}
                  >
                    {field.table === "Patient" ? "Patient" : "Sample"}
                  </Tag>
                  <Tag
                    style={{
                      backgroundColor: groupColor(field.group),
                    }}
                  >
                    {field.group}
                  </Tag>
                  <Tag
                    style={{
                      backgroundColor: "#8A9BA8",
                    }}
                  >
                    <Icon
                      icon={
                        field.class === "float" || field.class === "int"
                          ? "numerical"
                          : "layers"
                      }
                    />
                  </Tag>
                </div>
                key={field.field}
                onClick={handleClick}
                text={text}
              />
            );
          }}
          items={allFields}
          onItemSelect={(field) => add(field.field)}
          resetOnSelect
          popoverProps={{ minimal: true }}
          selectedItems={dataFields.map((field) =>
            getSelectedField(allFields, field)
          )}
          tagInputProps={{
            onRemove: ({ props: p }) =>
              remove(selectedFieldReverseLookup(allFields, p.children).field),
            rightElement:
              dataFields.length > 0 ? (
                <Button icon="cross" minimal onClick={() => clear()} />
              ) : undefined,
          }}
          tagRenderer={(field) =>
            field !== undefined ? <Tag>{field.name}</Tag> : <Tag />
          }
        />
      </div>
    </Tooltip>
  </div>
);

TopTrackDataFieldPicker.propTypes = {
  allFields: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      table: PropTypes.string.isRequired,
      group: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  dataFields: PropTypes.array.isRequired,
  tooltip: PropTypes.object.isRequired,
  add: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
};

export default TopTrackDataFieldPicker;
