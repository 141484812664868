import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  ButtonGroup,
  Divider,
  Tooltip,
  NonIdealState,
} from "@blueprintjs/core";
import { NavigationMapping } from "../../constants";
import Published from "../../containers/cohorts/Published";
import Saved from "../../containers/cohorts/Saved";
import Chart from "./Chart";
import ModuleLink from "../ModuleLink";
import CohortsSetOpMenu from "../../containers/cohorts/SetOpMenu";

const Container = ({
  publishedCohortIds,
  savedCohortIds,
  numSelectedIds,
  selectedCohorts,
  isFetchingPublishedCohorts,
  scrollIntoViewCohortId,
  saveCohort,
  matchPatientIds,
  selectAllPublished,
  selectAllSaved,
  clearCohortSelection,
}) => (
  <div className="cohorts-container">
    {scrollIntoViewCohortId !== "none" &&
    document.getElementById(scrollIntoViewCohortId) !== null ? (
      <button
        type="button"
        style={{ display: "none" }}
        onClick={document
          .getElementById(scrollIntoViewCohortId)
          .scrollIntoView()}
      >
        Scroll
      </button>
    ) : (
      <div />
    )}
    <div className="list">
      <div className="inner">
        {!isFetchingPublishedCohorts ? (
          publishedCohortIds.map((id) => <Published key={id} id={id} />)
        ) : (
          <div className="published-cohort">
            <div className="element bp3-skeleton" />
            <div className="element bp3-skeleton" />
            <div className="element bp3-skeleton" />
            <div className="element bp3-skeleton" />
            <div className="element bp3-skeleton" />
          </div>
        )}
        {!isFetchingPublishedCohorts && publishedCohortIds.length === 0 ? (
          <NonIdealState
            icon="eye-off"
            title="No published cohorts are visible"
            description="All of the published cohorts are hidden (by default). Use the search tool on the main page to add cohorts of interest to this panel."
            action=<ModuleLink pathname={NavigationMapping.HOME}>
              <Button
                small
                icon="search-template"
                intent="primary"
                text="Go to search tool"
              />
            </ModuleLink>
          />
        ) : (
          <div />
        )}
        <Divider />
        {savedCohortIds.map((id) => (
          <Saved key={id} id={id} />
        ))}
        {savedCohortIds.length === 0 ? (
          <NonIdealState
            icon="new-object"
            title="No saved cohorts"
            description="No cohorts have been saved by the user. Interact with plots or published cohorts to do so."
          />
        ) : (
          <div />
        )}
      </div>
      <div
        style={{
          position: "relative",
          bottom: "55px",
          zIndex: "5",
          padding: "8px",
        }}
      >
        <div className="bp3-toast" style={{ margin: "0" }}>
          <CohortsSetOpMenu />
          <div style={{ width: "-webkit-fill-available" }} />
          <ButtonGroup style={{ margin: "auto 0", padding: "5px" }}>
            <Tooltip content="Select all published cohorts" usePortal={false}>
              <Button
                disabled={publishedCohortIds.length === 0}
                small
                icon="endorsed"
                onClick={() => selectAllPublished()}
              />
            </Tooltip>
            <Tooltip content="Select all saved cohorts">
              <Button
                disabled={savedCohortIds.length === 0}
                small
                icon="saved"
                onClick={() => selectAllSaved()}
              />
            </Tooltip>
            <Tooltip
              content="Clear all selections"
              disabled={numSelectedIds === 0}
            >
              <Button
                active={numSelectedIds === 0}
                disabled={numSelectedIds === 0}
                small
                icon="clean"
                onClick={() => clearCohortSelection()}
              />
            </Tooltip>
          </ButtonGroup>
        </div>
      </div>
    </div>
    <Divider />
    <div className="comparison-plot-container">
      <Chart
        selectedCohorts={selectedCohorts}
        saveCohort={saveCohort}
        matchPatientIds={matchPatientIds}
      />
    </div>
  </div>
);

Container.propTypes = {
  publishedCohortIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  savedCohortIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  isFetchingPublishedCohorts: PropTypes.bool.isRequired,
  numSelectedIds: PropTypes.number.isRequired,
  selectedCohorts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      patientIds: PropTypes.arrayOf(PropTypes.string).isRequired,
      sampleIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
  scrollIntoViewCohortId: PropTypes.string.isRequired,
  saveCohort: PropTypes.func.isRequired,
  matchPatientIds: PropTypes.func.isRequired,
  selectAllPublished: PropTypes.func.isRequired,
  selectAllSaved: PropTypes.func.isRequired,
  clearCohortSelection: PropTypes.func.isRequired,
};

export default Container;
