import { connect } from "react-redux";
import * as d3 from "d3";
import { togglePublishedVisibility } from "../actions/cohorts";
import Search from "../components/Search";

const mapStateToProps = (state) => {
  const datasets = d3.group(
    state.dataFetch.cancerTypes.data,
    (d) => d.datasetName
  );
  const sampleInfo = state.dataFetch.sampleInfo.data;
  const { isFetching } = state.dataFetch.sampleInfo;

  const data = new Map();

  datasets.forEach((datasetSamples, datasetName, _unused) => {
    const map = new Map();
    const samplesSet = new Set(datasetSamples.map((s) => s.sampleId));
    datasetSamples.forEach((item) => map.set(item.sampleId, item));
    sampleInfo.forEach((item) => {
      if (!samplesSet.has(item.sampleId)) {
        return;
      }
      map.set(item.sampleId, { ...map.get(item.sampleId), ...item });
    });
    data.set(datasetName, Array.from(map.values()));
  });

  // Create map from published cohorts
  const published = new Map();
  state.cohort.present.published.forEach((p, _unused) => {
    published.set(p.name, p);
  });

  return {
    data,
    published,
    isFetching,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleVisibility: (name) => dispatch(togglePublishedVisibility(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
