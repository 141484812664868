import { connect } from "react-redux";
import { saveCohort } from "../../actions/cohorts";
import MutationChart from "../../components/mutation/Chart";

const mapStateToProps = (state) => ({
  data: state.dataFetch.mutation.data,
  geneList: state.mutation.present.geneList,
  sortBy: state.mutation.present.sortBy,
  selectedGene: state.mutation.present.selectedGene,
  selectedDataField: state.mutation.present.selectedDataField,
  options: state.mutation.present.options,
});

const mapDispatchToProps = (dispatch) => ({
  saveCohort: (patientIds, sampleIds, source, tags) =>
    dispatch(saveCohort(patientIds, sampleIds, source, tags)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MutationChart);
