import * as d3 from "d3";

export const NavigationMapping = {
  HOME: "HOME",
  DISTRIBUTION: "DISTRIBUTION",
  CORRELATION: "CORRELATION",
  MUTATION: "MUTATION",
  EXPRESSION: "EXPRESSION",
  VOLCANO: "VOLCANO",
  HLA: "HLA",
  SURVIVAL: "SURVIVAL",
};

export const EndpointMapping = {
  FETCH_SESSION: "fetch_session",
  UPDATE_SESSION: "update_session",
  STORE_SESSION: "store_session",
  FORK_SESSION: "fork_session",
  FORK_SHARABLE: "fork_sharable",
  POST_COMMENT: "post_comment",
  DATASETS: "datasets",
  SAMPLE_INFO: "sample_info",
  TREATMENTS: "treatments",
  CANCER_TYPES: "cancer_types",
  DNA_COUNTS: "dna_counts",
  RNA_COUNTS: "rna_counts",
  ALL_DATA_FIELDS: "all_data_fields",
  DISTRIBUTION: "distribution",
  CORRELATION: "correlation",
  MUTATION: "mutation",
  EXPRESSION: "expression",
  BATCH_CORRECTION: "batch_correction",
  HLA_ZYGOSITY_DENSITY: "hla_zygosity_density",
  HLA_ALLELE_PAIR_MATRIX: "hla_allele_pair_matrix",
  STATIC_HED_MATRIX: "static_hed_matrix",
  SURVIVAL: "survival",
  ALL_GENES: "all_genes",
  GENE_SETS: "gene_sets",
};

export const EndpointFieldMapping = {
  datasets: "datasets",
  sample_info: "sampleInfo",
  treatments: "treatments",
  cancer_types: "cancerTypes",
  dna_counts: "dnaCounts",
  rna_counts: "rnaCounts",
  all_data_fields: "allDataFields",
  distribution: "distribution",
  correlation: "correlation",
  mutation: "mutation",
  expression: "expression",
  batch_correction: "batchCorrectedExpression",
  hla_zygosity_density: "hlaZygosityDensity",
  hla_allele_pair_matrix: "hlaAllelePairMatrix",
  static_hed_matrix: "staticHEDMatrix",
  survival: "survival",
  all_genes: "allGenes",
  gene_sets: "geneSets",
};

export const EndpointTextMapping = {
  datasets: "Dataset",
  sample_info: "Sample Info",
  treatments: "Treatments",
  cancer_types: "Cancer Types",
  dna_counts: "DNA Counts",
  rna_counts: "RNA Counts",
  all_data_fields: "Data Fields",
  distribution: "Distribution",
  correlation: "Correlation",
  mutation: "Mutation",
  expression: "Expression",
  batch_correction: "Batch-Corrected Expression",
  hla_zygosity_density: "HLA Zygosity Density",
  hla_allele_pair_matrix: "HLA Allele Pair Matrix",
  static_hed_matrix: "Static HED Matrix",
  survival: "Survival",
  all_genes: "Genes",
  gene_sets: "Gene Sets",
};

export const legendColors = {
  Patient: "#5642A6",
  "Pre, First": "#2965CC",
  "On, First": "#2965CC",
  "Pre, Second": "#29A634",
  "On, Second": "#29A634",
};

export const legendSymbols = {
  Patient: "symbol-circle",
};

/*eslint-disable */
export const treatmentStatusMapping = {
  "PRE": "Pre",
  "ON": "On",
  "POST": "Post",
  "AFTER_START": "On/Post",
  "UNKNOWN_STATUS": "Unknown",
  "NAIVE": "ICI Naive",
  "NOT_APPLICABLE": "NA",
};

export const treatmentTargetMapping = {
  "PD_1": "PD-1",
  "PD_L1": "PD-L1",
  "CTLA_4": "CTLA-4",
  "PD_1_OR_PD_L1": "PD-1/PD-L1",
  "UNKNOWN_ICI_TARGET": "Unknown ICI",
  "COMBO": "Combo",
  "NON_ICI_TREATMENT": "Non-ICI Treatment",
  "NOT_APPLICABLE": "NA",
};
/* eslint-enable */

export const dataFieldsMetaTemplate = [
  {
    id: "age",
    name: "Age",
    type: "linear",
    color: (_unused) => (_unused2) => "blue",
    cmp: (a, b) => a.Age - b.Age,
  },
  {
    id: "sex",
    name: "Gender",
    type: "categorical",
    color: (_unused) => (sex) => {
      const colorMapping = {
        MALE: d3.rgb("rgb(22, 86, 139)"),
        FEMALE: d3.rgb("rgb(176, 26, 26)"),
        NA: d3.rgb("rgb(145, 145, 145)"),
      };
      return colorMapping[sex];
    },
    cmp: (a, b) => {
      const order = ["MALE", "FEMALE", "NA"];
      return order.indexOf(a.Gender) - order.indexOf(b.Gender);
    },
  },
  {
    id: "stage",
    name: "Stage",
    type: "categorical",
    color: (_unused) => (stage) => {
      const colorMapping = {
        "0": d3.rgb("rgb(27, 133, 184)"),
        I: d3.rgb("rgb(85, 158, 131)"),
        II: d3.rgb("rgb(100, 70, 93)"),
        III: d3.rgb("rgb(85, 158, 131)"),
        IV: d3.rgb("rgb(174, 90, 65)"),
        NA: d3.rgb("rgb(145, 145, 145)"),
      };
      return colorMapping[stage];
    },
    cmp: (a, b) => {
      const order = ["0", "I", "II", "III", "IV", "NA"];
      return order.indexOf(a.Stage) - order.indexOf(b.Stage);
    },
  },
  {
    id: "m_stage",
    name: "Metastasis Stage",
    type: "categorical",
    color: (_unused) => (mStage) => {
      const colorMapping = {
        M0: d3.rgb("rgb(27, 133, 184)"),
        M1: d3.rgb("rgb(85, 158, 131)"),
        MX: d3.rgb("rgb(100, 70, 93)"),
        M1A: d3.rgb("rgb(85, 158, 131)"),
        M1B: d3.rgb("rgb(174, 90, 65)"),
        NA: d3.rgb("rgb(145, 145, 145)"),
      };
      return colorMapping[mStage];
    },
    cmp: (a, b) => {
      const order = ["M0", "M1", "MX", "M1A", "M1B", "NA"];
      return (
        order.indexOf(a["Metastasis Stage"]) -
        order.indexOf(b["Metastasis Stage"])
      );
    },
  },
  {
    id: "recist",
    name: "RECIST",
    type: "categorical",
    color: (_unused) => (recist) => {
      const colorMapping = {
        PD: d3.rgb("rgb(176, 26, 26)"),
        SD: d3.rgb("rgb(255, 165, 0)"),
        PR: d3.rgb("rgb(15, 143, 70)"),
        CR: d3.rgb("rgb(22, 86, 139)"),
        NA: d3.rgb("rgb(145, 145, 145)"),
      };
      return colorMapping[recist];
    },
    cmp: (a, b) => {
      const order = ["PD", "SD", "PR", "CR", "NA"];
      return order.indexOf(a.RECIST) - order.indexOf(b.RECIST);
    },
  },
  {
    id: "raw_investigator_response",
    name: "Raw Investigator Response",
    type: "categorical",
    color: (dataFields) =>
      d3.scaleOrdinal(
        Array.from(
          new Set(dataFields.map((d) => d["Raw Investigator Response"]))
        ),
        d3.schemeCategory10
      ),
    cmp: (a, b) =>
      d3.ascending(
        a["Raw Investigator Response"],
        b["Raw Investigator Response"]
      ),
  },
  {
    id: "two_type_response",
    name: "Two-Tier Response",
    type: "categorical",
    color: (_unused) => (twoTierResponse) => {
      const colorMapping = {
        NonResponder: d3.rgb("rgb(176, 26, 26)"),
        Responder: d3.rgb("rgb(15, 143, 70)"),
        NA: d3.rgb("rgb(145, 145, 145)"),
      };
      return colorMapping[twoTierResponse];
    },
    cmp: (a, b) => {
      const order = ["NonResponder", "Responder", "NA"];
      return (
        order.indexOf(a["Two-Tier Response"]) -
        order.indexOf(b["Two-Tier Response"])
      );
    },
  },
  {
    id: "three_type_response",
    name: "Three-Tier Response",
    type: "categorical",
    color: (_unused) => (threeTierResponse) => {
      const colorMapping = {
        NonResponder: d3.rgb("rgb(176, 26, 26)"),
        Stable: d3.rgb("rgb(255, 165, 0)"),
        Responder: d3.rgb("rgb(15, 143, 70)"),
        NA: d3.rgb("rgb(145, 145, 145)"),
      };
      return colorMapping[threeTierResponse];
    },
    cmp: (a, b) => {
      const order = ["NonResponder", "Stable", "Responder", "NA"];
      return (
        order.indexOf(a["Three-Tier Response"]) -
        order.indexOf(b["Three-Tier Response"])
      );
    },
  },
  {
    id: "cancer_type",
    name: "Cancer Type",
    type: "categorical",
    color: (dataFields) =>
      d3.scaleOrdinal(
        Array.from(new Set(dataFields.map((d) => d["Cancer Type"]))),
        d3.schemeCategory10
      ),
    cmp: (a, b) => d3.ascending(a["Cancer Type"], b["Cancer Type"]),
  },
  {
    id: "msi_type",
    name: "MSI Type",
    type: "categorical",
    color: (dataFields) =>
      d3.scaleOrdinal(
        Array.from(new Set(dataFields.map((d) => d["MSI Type"]))),
        d3.schemeCategory10
      ),
    cmp: (a, b) => d3.ascending(a["MSI Type"], b["MSI Type"]),
  },
  {
    id: "has_dominant_mut_sig",
    name: "Has Dominant Mutation Signature",
    type: "categorical",
    color: (dataFields) =>
      d3.scaleOrdinal(
        Array.from(
          new Set(dataFields.map((d) => d["Has Dominant Mutation Signature"]))
        ),
        d3.schemeCategory10
      ),
    cmp: (a, b) =>
      d3.ascending(
        a["Has Dominant Mutation Signature"],
        b["Has Dominant Mutation Signature"]
      ),
  },
  {
    id: "has_mut_dna_damage_repair_pathway",
    name: "Has Mutations in DNA Damage Repair Pathway",
    type: "categorical",
    color: (dataFields) =>
      d3.scaleOrdinal(
        Array.from(
          new Set(
            dataFields.map(
              (d) => d["Has Mutations in DNA Damage Repair Pathway"]
            )
          )
        ),
        d3.schemeCategory10
      ),
    cmp: (a, b) =>
      d3.ascending(
        a["Has Mutations in DNA Damage Repair Pathway"],
        b["Has Mutations in DNA Damage Repair Pathway"]
      ),
  },
  {
    id: "mutation_count",
    name: "Mutation Count",
    type: "log",
    color: (_unused) => (_unused2) => "green",
    cmp: (a, b) => a["Mutation Count"] - b["Mutation Count"],
  },
  {
    id: "indel_count",
    name: "Indel Count",
    type: "log",
    color: (_unused) => (_unused2) => "green",
    cmp: (a, b) => a["Indel Count"] - b["Indel Count"],
  },
  {
    id: "tmb",
    name: "Tumor Mutation Burden",
    type: "log",
    color: (_unused) => (_unused2) => "green",
    cmp: (a, b) => a["Tumor Mutation Burden"] - b["Tumor Mutation Burden"],
  },
  {
    id: "tmb_indel",
    name: "Tumor Mutation Burden of Indel Mutations",
    type: "log",
    color: (_unused) => (_unused2) => "green",
    cmp: (a, b) =>
      a["Tumor Mutation Burden of Indel Mutations"] -
      b["Tumor Mutation Burden of Indel Mutations"],
  },
  {
    id: "hla_a_zygosity",
    name: "HLA-A Zygosity",
    type: "categorical",
    color: (_unused) => (zygosity) => {
      const colorMapping = {
        HOMOZYGOUS: d3.rgb("rgb(197, 70, 48)"),
        HETEROZYGOUS: d3.rgb("rgb(54, 52, 92)"),
        NA: d3.rgb("rgb(145, 145, 145)"),
      };
      return colorMapping[zygosity];
    },
    cmp: (a, b) => {
      const order = ["HOMOZYGOUS", "HETEROZYGOUS", "NA"];
      return (
        order.indexOf(a["HLA-A Zygosity"]) - order.indexOf(b["HLA-A Zygosity"])
      );
    },
  },
  {
    id: "hla_b_zygosity",
    name: "HLA-B Zygosity",
    type: "categorical",
    color: (_unused) => (zygosity) => {
      const colorMapping = {
        HOMOZYGOUS: d3.rgb("rgb(197, 70, 48)"),
        HETEROZYGOUS: d3.rgb("rgb(54, 52, 92)"),
        NA: d3.rgb("rgb(145, 145, 145)"),
      };
      return colorMapping[zygosity];
    },
    cmp: (a, b) => {
      const order = ["HOMOZYGOUS", "HETEROZYGOUS", "NA"];
      return (
        order.indexOf(a["HLA-B Zygosity"]) - order.indexOf(b["HLA-B Zygosity"])
      );
    },
  },
  {
    id: "hla_c_zygosity",
    name: "HLA-C Zygosity",
    type: "categorical",
    color: (_unused) => (zygosity) => {
      const colorMapping = {
        HOMOZYGOUS: d3.rgb("rgb(197, 70, 48)"),
        HETEROZYGOUS: d3.rgb("rgb(54, 52, 92)"),
        NA: d3.rgb("rgb(145, 145, 145)"),
      };
      return colorMapping[zygosity];
    },
    cmp: (a, b) => {
      const order = ["HOMOZYGOUS", "HETEROZYGOUS", "NA"];
      return (
        order.indexOf(a["HLA-C Zygosity"]) - order.indexOf(b["HLA-C Zygosity"])
      );
    },
  },
  {
    id: "hla_zygosity",
    name: "HLA Zygosity",
    type: "categorical",
    color: (_unused) => (zygosity) => {
      const colorMapping = {
        HOMOZYGOUS: d3.rgb("rgb(197, 70, 48)"),
        FULLYHETEROZYGOUS: d3.rgb("rgb(54, 52, 92)"),
        NA: d3.rgb("rgb(145, 145, 145)"),
      };
      return colorMapping[zygosity];
    },
    cmp: (a, b) => {
      const order = ["HOMOZYGOUS", "HETEROZYGOUS", "NA"];
      return (
        order.indexOf(a["HLA Zygosity"]) - order.indexOf(b["HLA Zygosity"])
      );
    },
  },
  {
    id: "hla_a_evolutionary_divergence",
    name: "HLA-A Evolutionary Divergence",
    type: "linear",
    color: (_unused) => (_unused2) => "green",
    cmp: (a, b) =>
      a["HLA-A Evolutionary Divergence"] - b["HLA-A Evolutionary Divergence"],
  },
  {
    id: "hla_b_evolutionary_divergence",
    name: "HLA-B Evolutionary Divergence",
    type: "linear",
    color: (_unused) => (_unused2) => "green",
    cmp: (a, b) =>
      a["HLA-B Evolutionary Divergence"] - b["HLA-B Evolutionary Divergence"],
  },
  {
    id: "hla_c_evolutionary_divergence",
    name: "HLA-C Evolutionary Divergence",
    type: "linear",
    color: (_unused) => (_unused2) => "green",
    cmp: (a, b) =>
      a["HLA-C Evolutionary Divergence"] - b["HLA-C Evolutionary Divergence"],
  },
  {
    id: "hla_mean_evolutionary_divergence",
    name: "Mean HLA Evolutionary Divergence",
    type: "linear",
    color: (_unused) => (_unused2) => "green",
    cmp: (a, b) =>
      a["Mean HLA Evolutionary Divergence"] -
      b["Mean HLA Evolutionary Divergence"],
  },
];

export const defaultTreatmentTree = [
  {
    disabled: false,
    icon: "resolve",
    id: 0,
    hasCaret: true,
    isExpanded: true,
    isSelected: true,
    label: "Logical OR",
    nodeData: "op",
    childNodes: [
      {
        disabled: false,
        icon: "prescription",
        id: 1,
        hasCaret: false,
        isExpanded: false,
        isSelected: false,
        label: "Pre PD-1",
        nodeData: { status: "PRE", target: "PD_1" },
        childNodes: [],
      },
      {
        disabled: false,
        icon: "prescription",
        id: 2,
        hasCaret: false,
        isExpanded: false,
        isSelected: false,
        label: "Pre PD-L1",
        nodeData: { status: "PRE", target: "PD_L1" },
        childNodes: [],
      },
      {
        disabled: false,
        icon: "prescription",
        id: 3,
        hasCaret: false,
        isExpanded: false,
        isSelected: false,
        label: "Pre CTLA-4",
        nodeData: { status: "PRE", target: "CTLA_4" },
        childNodes: [],
      },
    ],
  },
];

export const presetTreatmentTreeMapping = {
  "Pre Treatment": [
    {
      disabled: false,
      icon: "resolve",
      id: 0,
      hasCaret: true,
      isExpanded: true,
      isSelected: true,
      label: "Logical OR",
      nodeData: "op",
      childNodes: [
        {
          disabled: false,
          icon: "prescription",
          id: 1,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Pre PD-1",
          nodeData: { status: "PRE", target: "PD_1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 2,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Pre PD-L1",
          nodeData: { status: "PRE", target: "PD_L1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 3,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Pre CTLA-4",
          nodeData: { status: "PRE", target: "CTLA_4" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 4,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Pre PD-1/PD-L1",
          nodeData: { status: "PRE", target: "PD_1_OR_PD_L1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 5,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Pre Unknown ICI",
          nodeData: { status: "PRE", target: "UNKNOWN_ICI_TARGET" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 6,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Pre Combo",
          nodeData: { status: "PRE", target: "COMBO" },
          childNodes: [],
        },
      ],
    },
  ],
  "On/Post Treatment": [
    {
      disabled: false,
      icon: "resolve",
      id: 0,
      hasCaret: true,
      isExpanded: true,
      isSelected: true,
      label: "Logical OR",
      nodeData: "op",
      childNodes: [
        {
          disabled: false,
          icon: "prescription",
          id: 1,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On/Post PD-1",
          nodeData: { status: "AFTER_START", target: "PD_1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 2,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On/Post PD-L1",
          nodeData: { status: "AFTER_START", target: "PD_L1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 3,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On/Post CTLA-4",
          nodeData: { status: "AFTER_START", target: "CTLA_4" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 4,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On/Post PD-1/PD-L1",
          nodeData: { status: "AFTER_START", target: "PD_1_OR_PD_L1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 5,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On/Post Unknown ICI",
          nodeData: { status: "AFTER_START", target: "UNKNOWN_ICI_TARGET" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 6,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On/Post Combo",
          nodeData: { status: "AFTER_START", target: "COMBO" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 7,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On PD-1",
          nodeData: { status: "ON", target: "PD_1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 8,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On PD-L1",
          nodeData: { status: "ON", target: "PD_L1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 9,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On CTLA-4",
          nodeData: { status: "ON", target: "CTLA_4" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 10,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On PD-1/PD-L1",
          nodeData: { status: "ON", target: "PD_1_OR_PD_L1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 11,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On Unknown ICI",
          nodeData: { status: "ON", target: "UNKNOWN_ICI_TARGET" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 12,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On Combo",
          nodeData: { status: "ON", target: "COMBO" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 13,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Post PD-1",
          nodeData: { status: "POST", target: "PD_1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 14,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Post PD-L1",
          nodeData: { status: "POST", target: "PD_L1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 15,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Post CTLA-4",
          nodeData: { status: "POST", target: "CTLA_4" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 16,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Post PD-1/PD-L1",
          nodeData: { status: "POST", target: "PD_1_OR_PD_L1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 17,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Post Unknown ICI",
          nodeData: { status: "POST", target: "UNKNOWN_ICI_TARGET" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 18,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Post Combo",
          nodeData: { status: "POST", target: "COMBO" },
          childNodes: [],
        },
      ],
    },
  ],
  "On Treatment": [
    {
      disabled: false,
      icon: "resolve",
      id: 0,
      hasCaret: true,
      isExpanded: true,
      isSelected: true,
      label: "Logical OR",
      nodeData: "op",
      childNodes: [
        {
          disabled: false,
          icon: "prescription",
          id: 1,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On PD-1",
          nodeData: { status: "ON", target: "PD_1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 2,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On PD-L1",
          nodeData: { status: "ON", target: "PD_L1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 3,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On CTLA-4",
          nodeData: { status: "ON", target: "CTLA_4" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 4,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On PD-1/PD-L1",
          nodeData: { status: "ON", target: "PD_1_OR_PD_L1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 5,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On Unknown ICI",
          nodeData: { status: "ON", target: "UNKNOWN_ICI_TARGET" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 6,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On Combo",
          nodeData: { status: "ON", target: "COMBO" },
          childNodes: [],
        },
      ],
    },
  ],
  Anytime: [
    {
      disabled: false,
      icon: "resolve",
      id: 0,
      hasCaret: true,
      isExpanded: true,
      isSelected: true,
      label: "Logical OR",
      nodeData: "op",
      childNodes: [
        {
          disabled: false,
          icon: "prescription",
          id: 1,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Pre PD-1",
          nodeData: { status: "PRE", target: "PD_1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 2,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Pre PD-L1",
          nodeData: { status: "PRE", target: "PD_L1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 3,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Pre CTLA-4",
          nodeData: { status: "PRE", target: "CTLA_4" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 4,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Pre PD-1/PD-L1",
          nodeData: { status: "PRE", target: "PD_1_OR_PD_L1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 5,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Pre Unknown ICI",
          nodeData: { status: "PRE", target: "UNKNOWN_ICI_TARGET" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 6,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Pre Combo",
          nodeData: { status: "PRE", target: "COMBO" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 7,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On/Post PD-1",
          nodeData: { status: "AFTER_START", target: "PD_1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 8,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On/Post PD-L1",
          nodeData: { status: "AFTER_START", target: "PD_L1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 9,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On/Post CTLA-4",
          nodeData: { status: "AFTER_START", target: "CTLA_4" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 10,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On/Post PD-1/PD-L1",
          nodeData: { status: "AFTER_START", target: "PD_1_OR_PD_L1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 11,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On/Post Unknown ICI",
          nodeData: { status: "AFTER_START", target: "UNKNOWN_ICI_TARGET" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 12,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On/Post Combo",
          nodeData: { status: "AFTER_START", target: "COMBO" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 13,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On PD-1",
          nodeData: { status: "ON", target: "PD_1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 14,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On PD-L1",
          nodeData: { status: "ON", target: "PD_L1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 15,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On CTLA-4",
          nodeData: { status: "ON", target: "CTLA_4" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 16,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On PD-1/PD-L1",
          nodeData: { status: "ON", target: "PD_1_OR_PD_L1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 17,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On Unknown ICI",
          nodeData: { status: "ON", target: "UNKNOWN_ICI_TARGET" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 18,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On Combo",
          nodeData: { status: "ON", target: "COMBO" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 19,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Post PD-1",
          nodeData: { status: "POST", target: "PD_1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 20,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Post PD-L1",
          nodeData: { status: "POST", target: "PD_L1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 21,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Post CTLA-4",
          nodeData: { status: "POST", target: "CTLA_4" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 22,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Post PD-1/PD-L1",
          nodeData: { status: "POST", target: "PD_1_OR_PD_L1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 23,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Post Unknown ICI",
          nodeData: { status: "POST", target: "UNKNOWN_ICI_TARGET" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 24,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Post Combo",
          nodeData: { status: "POST", target: "COMBO" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 25,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Unknown PD-1",
          nodeData: { status: "UNKNOWN", target: "PD_1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 26,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Unknown PD-L1",
          nodeData: { status: "UNKNOWN", target: "PD_L1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 27,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Unknown CTLA-4",
          nodeData: { status: "UNKNOWN", target: "CTLA_4" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 28,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Unknown PD-1/PD-L1",
          nodeData: { status: "UNKNOWN", target: "PD_1_OR_PD_L1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 29,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Unknown Unknown ICI",
          nodeData: { status: "UNKNOWN", target: "UNKNOWN_ICI_TARGET" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 30,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Unknown Combo",
          nodeData: { status: "UNKNOWN", target: "COMBO" },
          childNodes: [],
        },
      ],
    },
  ],
  /* Published Cohorts */
  "VanAllen-2015-Science": [
    {
      disabled: false,
      icon: "prescription",
      id: 0,
      hasCaret: false,
      isExpanded: false,
      isSelected: false,
      label: "Pre CTLA-4",
      nodeData: { status: "PRE", target: "CTLA_4" },
      childNodes: [],
    },
  ],
  "Riaz-2017-Cell": [
    {
      disabled: false,
      icon: "resolve",
      id: 0,
      hasCaret: true,
      isExpanded: true,
      isSelected: true,
      label: "Logical OR",
      nodeData: "op",
      childNodes: [
        {
          disabled: false,
          icon: "resolve",
          id: 1,
          hasCaret: true,
          isExpanded: true,
          isSelected: false,
          label: "Logical AND",
          nodeData: "op",
          childNodes: [
            {
              disabled: false,
              icon: "prescription",
              id: 3,
              hasCaret: false,
              isExpanded: false,
              isSelected: false,
              label: "Pre PD-1",
              nodeData: { status: "PRE", target: "PD_1" },
              childNodes: [],
            },
            {
              disabled: false,
              icon: "prescription",
              id: 4,
              hasCaret: false,
              isExpanded: false,
              isSelected: false,
              label: "Post CTLA-4",
              nodeData: { status: "POST", target: "CTLA_4" },
              childNodes: [],
            },
          ],
        },
        {
          disabled: false,
          icon: "resolve",
          id: 2,
          hasCaret: true,
          isExpanded: true,
          isSelected: false,
          label: "Logical AND",
          nodeData: "op",
          childNodes: [
            {
              disabled: false,
              icon: "prescription",
              id: 5,
              hasCaret: false,
              isExpanded: false,
              isSelected: false,
              label: "Pre PD-1",
              nodeData: { status: "PRE", target: "PD_1" },
              childNodes: [],
            },
            {
              disabled: false,
              icon: "resolve",
              id: 6,
              hasCaret: true,
              isExpanded: true,
              isSelected: false,
              label: "Logical NOT",
              nodeData: "op",
              childNodes: [
                {
                  disabled: false,
                  icon: "prescription",
                  id: 7,
                  hasCaret: false,
                  isExpanded: false,
                  isSelected: false,
                  label: "Post CTLA-4",
                  nodeData: { status: "POST", target: "CTLA_4" },
                  childNodes: [],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  "Valero-2021-NatGen": [
    {
      disabled: false,
      icon: "resolve",
      id: 0,
      hasCaret: true,
      isExpanded: true,
      isSelected: true,
      label: "Logical OR",
      nodeData: "op",
      childNodes: [
        {
          disabled: false,
          icon: "prescription",
          id: 1,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Pre Unknown ICI",
          nodeData: { status: "PRE", target: "UNKNOWN_ICI_TARGET" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 2,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On/Post Unknown ICI",
          nodeData: { status: "AFTER_START", target: "UNKNOWN_ICI_TARGET" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 3,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "ICI Naive",
          nodeData: { status: "NAIVE", target: "NOT_APPLICABLE" },
          childNodes: [],
        },
      ],
    },
  ],
  "Gide-2019-CancerCell": [
    {
      disabled: false,
      icon: "resolve",
      id: 0,
      hasCaret: true,
      isExpanded: true,
      isSelected: true,
      label: "Logical OR",
      nodeData: "op",
      childNodes: [
        {
          disabled: false,
          icon: "prescription",
          id: 1,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Pre PD-1",
          nodeData: { status: "PRE", target: "PD_1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 2,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On PD-1",
          nodeData: { status: "ON", target: "PD_1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 3,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Pre Combo",
          nodeData: { status: "PRE", target: "COMBO" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 4,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On Combo",
          nodeData: { status: "ON", target: "COMBO" },
          childNodes: [],
        },
      ],
    },
  ],
  "Hugo-2016-Cell": [
    {
      disabled: false,
      icon: "resolve",
      id: 0,
      hasCaret: true,
      isExpanded: true,
      isSelected: true,
      label: "Logical OR",
      nodeData: "op",
      childNodes: [
        {
          disabled: false,
          icon: "prescription",
          id: 1,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Pre PD-1",
          nodeData: { status: "PRE", target: "PD_1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 2,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On PD-1",
          nodeData: { status: "ON", target: "PD_1" },
          childNodes: [],
        },
      ],
    },
  ],
  "Auslander-2018-NatMed": [
    {
      disabled: false,
      icon: "resolve",
      id: 0,
      hasCaret: true,
      isExpanded: true,
      isSelected: true,
      label: "Logical AND",
      nodeData: "op",
      childNodes: [
        {
          disabled: false,
          icon: "resolve",
          id: 1,
          hasCaret: true,
          isExpanded: true,
          isSelected: false,
          label: "Logical OR",
          nodeData: "op",
          childNodes: [
            {
              disabled: false,
              icon: "prescription",
              id: 2,
              hasCaret: false,
              isExpanded: false,
              isSelected: false,
              label: "Pre CTLA-4",
              nodeData: { status: "PRE", target: "CTLA_4" },
              childNodes: [],
            },
            {
              disabled: false,
              icon: "prescription",
              id: 3,
              hasCaret: false,
              isExpanded: false,
              isSelected: false,
              label: "Pre PD-1",
              nodeData: { status: "PRE", target: "PD_1" },
              childNodes: [],
            },
            {
              disabled: false,
              icon: "prescription",
              id: 4,
              hasCaret: false,
              isExpanded: false,
              isSelected: false,
              label: "Pre Combo",
              nodeData: { status: "PRE", target: "COMBO" },
              childNodes: [],
            },
          ],
        },
        {
          disabled: false,
          icon: "resolve",
          id: 5,
          hasCaret: true,
          isExpanded: true,
          isSelected: false,
          label: "Logical NOT",
          nodeData: "op",
          childNodes: [
            {
              disabled: false,
              icon: "resolve",
              id: 6,
              hasCaret: true,
              isExpanded: true,
              isSelected: false,
              label: "Logical OR",
              nodeData: "op",
              childNodes: [
                {
                  disabled: false,
                  icon: "prescription",
                  id: 7,
                  hasCaret: false,
                  isExpanded: false,
                  isSelected: false,
                  label: "Post CTLA-4",
                  nodeData: { status: "POST", target: "CTLA_4" },
                  childNodes: [],
                },
                {
                  disabled: false,
                  icon: "prescription",
                  id: 8,
                  hasCaret: false,
                  isExpanded: false,
                  isSelected: false,
                  label: "Post PD-1",
                  nodeData: { status: "POST", target: "PD_1" },
                  childNodes: [],
                },
                {
                  disabled: false,
                  icon: "prescription",
                  id: 9,
                  hasCaret: false,
                  isExpanded: false,
                  isSelected: false,
                  label: "Post Combo",
                  nodeData: { status: "POST", target: "COMBO" },
                  childNodes: [],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  "Rizvi-2015-Science": [
    {
      disabled: false,
      icon: "prescription",
      id: 0,
      hasCaret: false,
      isExpanded: false,
      isSelected: false,
      label: "Pre PD-1",
      nodeData: { status: "PRE", target: "PD_1" },
      childNodes: [],
    },
  ],
  "Cloughesy-2019-NatMed": [
    {
      disabled: false,
      icon: "resolve",
      id: 0,
      hasCaret: true,
      isExpanded: true,
      isSelected: true,
      label: "Logical OR",
      nodeData: "op",
      childNodes: [
        {
          disabled: false,
          icon: "prescription",
          id: 1,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Pre PD-1",
          nodeData: { status: "PRE", target: "PD_1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 2,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "On PD-1",
          nodeData: { status: "ON", target: "PD_1" },
          childNodes: [],
        },
      ],
    },
  ],
  "Gao-2016-Cell": [
    {
      disabled: false,
      icon: "prescription",
      id: 0,
      hasCaret: false,
      isExpanded: false,
      isSelected: false,
      label: "Pre CTLA-4",
      nodeData: { status: "PRE", target: "CTLA_4" },
      childNodes: [],
    },
  ],
  "Miao-2018-NatGen": [
    {
      disabled: false,
      icon: "resolve",
      id: 0,
      hasCaret: true,
      isExpanded: true,
      isSelected: true,
      label: "Logical OR",
      nodeData: "op",
      childNodes: [
        {
          disabled: false,
          icon: "prescription",
          id: 1,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Pre PD-1",
          nodeData: { status: "PRE", target: "PD_1" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 2,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Pre CTLA-4",
          nodeData: { status: "PRE", target: "CTLA_4" },
          childNodes: [],
        },
        {
          disabled: false,
          icon: "prescription",
          id: 3,
          hasCaret: false,
          isExpanded: false,
          isSelected: false,
          label: "Pre Combo",
          nodeData: { status: "PRE", target: "COMBO" },
          childNodes: [],
        },
      ],
    },
  ],
};
