/* PUBLISHED COHORTS */

export const togglePublished = (name) => ({
  type: "TOGGLE_PUBLISHED",
  name,
});

export const selectAllPublishedCohorts = () => ({
  type: "SELECT_ALL_PUBLISHED_COHORTS",
});

export const togglePublishedVisibility = (name) => ({
  type: "TOGGLE_PUBLISHED_VISIBILITY",
  name,
});

/* SAVED COHORTS */

export const saveCohort = (patientIds, sampleIds, source, tags) => ({
  type: "SAVE_COHORT",
  patientIds,
  sampleIds,
  source,
  tags,
  createdAt: Date.now(),
});

export const confirmCohorts = (cohortIds) => ({
  type: "CONFIRM_COHORTS",
  cohortIds,
});

export const removeCohort = (id) => ({
  type: "REMOVE_COHORT",
  id,
});

export const renameCohort = (id, name) => ({
  type: "RENAME_COHORT",
  id,
  name,
});

export const toggleCohort = (id) => ({
  type: "TOGGLE_COHORT",
  id,
});

export const toggleUseFixedSamples = (id) => ({
  type: "TOGGLE_USE_FIXED_SAMPLES",
  id,
});

export const selectAllSavedCohorts = () => ({
  type: "SELECT_ALL_SAVED_COHORTS",
});

/* PUBLISHED & SAVED COHORTS */

export const selectTreatmentTreeNode = (cohortId, nodeId) => ({
  type: "SELECT_TREATMENT_TREE_NODE",
  cohortId,
  nodeId,
});

export const expandTreatmentTreeNode = (cohortId, nodeId) => ({
  type: "EXPAND_TREATMENT_TREE_NODE",
  cohortId,
  nodeId,
});

export const collapseTreatmentTreeNode = (cohortId, nodeId) => ({
  type: "COLLAPSE_TREATMENT_TREE_NODE",
  cohortId,
  nodeId,
});

export const insertTreatmentTreeNode = (
  cohortId,
  op,
  status,
  target,
  icon,
  isLeaf
) => ({
  type: "INSERT_TREATMENT_TREE_NODE",
  cohortId,
  op,
  status,
  target,
  icon,
  isLeaf,
});

export const replaceTreatmentTreeNodes = (cohortId, preset) => ({
  type: "REPLACE_TREATMENT_TREE_NODES",
  cohortId,
  preset,
});

export const removeTreatmentTreeNode = (cohortId) => ({
  type: "REMOVE_TREATMENT_TREE_NODE",
  cohortId,
});

export const clearTreatmentTreeNodes = (cohortId) => ({
  type: "CLEAR_TREATMENT_TREE_NODES",
  cohortId,
});

export const toggleApplyToPatients = (id) => ({
  type: "TOGGLE_APPLY_TO_PATIENTS",
  id,
});

export const clearCohortSelection = () => ({
  type: "CLEAR_COHORT_SELECTION",
});

export const highlightCohort = (id) => ({
  type: "HIGHLIGHT_COHORT",
  id,
});

export const clearHighlightedCohort = () => ({
  type: "CLEAR_HIGHLIGHTED_COHORT",
});

/* PENDING COHORTS */

export const removePendingCohorts = (cohortIds) => ({
  type: "REMOVE_PENDING_COHORTS",
  cohortIds,
});

export const renamePendingCohort = (cohortId, name) => ({
  type: "RENAME_PENDING_COHORT",
  cohortId,
  name,
});
