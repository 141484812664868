/* DISTRIBUTION */

export const selectDistributionSource = (source) => ({
  type: "SELECT_DISTRIBUTION_SOURCE",
  source,
});

export const selectDistributionDataField = (field) => ({
  type: "SELECT_DISTRIBUTION_DATA_FIELD",
  field,
});

export const selectDistributionDataFieldScale = (scale) => ({
  type: "SELECT_DISTRIBUTION_DATA_FIELD_SCALE",
  scale,
});

export const selectDistributionTabId = (id) => ({
  type: "SELECT_DISTRIBUTION_TAB_ID",
  id,
});

/* CORRELATION */

export const selectCorrelationSource = (source) => ({
  type: "SELECT_CORRELATION_SOURCE",
  source,
});

export const selectCorrelationFirstDataField = (field) => ({
  type: "SELECT_CORRELATION_FIRST_DATA_FIELD",
  field,
});

export const selectCorrelationSecondDataField = (field) => ({
  type: "SELECT_CORRELATION_SECOND_DATA_FIELD",
  field,
});

export const selectCorrelationFirstDataFieldScale = (scale) => ({
  type: "SELECT_CORRELATION_FIRST_DATA_FIELD_SCALE",
  scale,
});

export const selectCorrelationSecondDataFieldScale = (scale) => ({
  type: "SELECT_CORRELATION_SECOND_DATA_FIELD_SCALE",
  scale,
});

export const swapCorrelationDataFields = () => ({
  type: "SWAP_CORRELATION_DATA_FIELDS",
});

export const selectCorrelationTabId = (id) => ({
  type: "SELECT_CORRELATION_TAB_ID",
  id,
});

/* MUTATION */

export const addMutationGene = (gene) => ({
  type: "ADD_MUTATION_GENE",
  gene,
});

export const addMulitpleMutationGenes = (geneList) => ({
  type: "ADD_MULTIPLE_MUTATION_GENES",
  geneList,
});

export const selectMutationGeneSet = (geneSet) => ({
  type: "SELECT_MUTATION_GENE_SET",
  geneSet,
});

export const removeMutationGene = (gene) => ({
  type: "REMOVE_MUTATION_GENE",
  gene,
});

export const clearMutationGenes = () => ({
  type: "CLEAR_MUTATION_GENES",
});

export const selectMutationSortBy = (sortBy) => ({
  type: "SELECT_MUTATION_SORT_BY",
  sortBy,
});

export const selectMutationSortByGene = (gene) => ({
  type: "SELECT_MUTATION_SORT_BY_GENE",
  gene,
});

export const addMutationDataField = (dataField) => ({
  type: "ADD_MUTATION_DATA_FIELD",
  dataField,
});

export const removeMutationDataField = (dataField) => ({
  type: "REMOVE_MUTATION_DATA_FIELD",
  dataField,
});

export const clearMutationDataFields = () => ({
  type: "CLEAR_MUTATION_DATA_FIELDS",
});

export const selectMutationSortByDataField = (dataField) => ({
  type: "SELECT_MUTATION_SORT_BY_DATA_FIELD",
  dataField,
});

export const selectMutationInteraction = (interaction) => ({
  type: "SELECT_MUTATION_INTERACTION",
  interaction,
});

/* EXPRESSION */

export const selectExpressionSource = (source) => ({
  type: "SELECT_EXPRESSION_SOURCE",
  source,
});

export const addExpressionGene = (gene) => ({
  type: "ADD_EXPRESSION_GENE",
  gene,
});

export const addMulitpleExpressionGenes = (geneList) => ({
  type: "ADD_MULTIPLE_EXPRESSION_GENES",
  geneList,
});

export const selectExpressionGeneSet = (geneSet) => ({
  type: "SELECT_EXPRESSION_GENE_SET",
  geneSet,
});

export const removeExpressionGene = (gene) => ({
  type: "REMOVE_EXPRESSION_GENE",
  gene,
});

export const clearExpressionGenes = () => ({
  type: "CLEAR_EXPRESSION_GENES",
});

export const selectExpressionSortBy = (sortBy) => ({
  type: "SELECT_EXPRESSION_SORT_BY",
  sortBy,
});

export const selectExpressionSortByGene = (gene) => ({
  type: "SELECT_EXPRESSION_SORT_BY_GENE",
  gene,
});

export const addExpressionDataField = (dataField) => ({
  type: "ADD_EXPRESSION_DATA_FIELD",
  dataField,
});

export const removeExpressionDataField = (dataField) => ({
  type: "REMOVE_EXPRESSION_DATA_FIELD",
  dataField,
});

export const clearExpressionDataFields = () => ({
  type: "CLEAR_EXPRESSION_DATA_FIELDS",
});

export const selectExpressionSortByDataField = (dataField) => ({
  type: "SELECT_EXPRESSION_SORT_BY_DATA_FIELD",
  dataField,
});

export const selectExpressionScale = (scale) => ({
  type: "SELECT_EXPRESSION_SCALE",
  scale,
});

export const selectExpressionNormalize = (normalize) => ({
  type: "SELECT_EXPRESSION_NORMALIZE",
  normalize,
});

export const toggleExpressionDataFieldsWithLogScale = (dataField) => ({
  type: "TOGGLE_EXPRESSION_DATA_FIELDS_WITH_LOG_SCALE",
  dataField,
});

export const selectExpressionTabId = (id) => ({
  type: "SELECT_EXPRESSION_TAB_ID",
  id,
});

/* VOLCANO */

export const toggleVolcanoDialog = () => ({
  type: "TOGGLE_VOLCANO_DIALOG",
});

export const addVolcanoGene = (gene) => ({
  type: "ADD_VOLCANO_GENE",
  gene,
});

export const addMulitpleVolcanoGenes = (geneList) => ({
  type: "ADD_MULTIPLE_VOLCANO_GENES",
  geneList,
});

export const selectVolcanoGeneSet = (geneSet) => ({
  type: "SELECT_VOLCANO_GENE_SET",
  geneSet,
});

export const removeVolcanoGene = (gene) => ({
  type: "REMOVE_VOLCANO_GENE",
  gene,
});

export const clearVolcanoGenes = () => ({
  type: "CLEAR_VOLCANO_GENES",
});

export const toggleVolcanoDoShrinkFoldChange = () => ({
  type: "TOGGLE_VOLCANO_DO_SHRINK_FOLD_CHANGE",
});

export const selectVolcanoColoring = (coloring) => ({
  type: "SELECT_VOLCANO_COLORING",
  coloring,
});

export const selectVolcanoInteraction = (interaction) => ({
  type: "SELECT_VOLCANO_INTERACTION",
  interaction,
});

export const selectVolcanoPcaDomain = (pcaDomain) => ({
  type: "SELECT_VOLCANO_PCA_DOMAIN",
  pcaDomain,
});

/* HLA */

export const selectHLAPlotTabId = (id) => ({
  type: "SELECT_HLA_PLOT_TAB_ID",
  id,
});

export const selectHLATabId = (id) => ({
  type: "SELECT_HLA_TAB_ID",
  id,
});

/* SURVIVAL */

export const toggleConfidenceInterval = () => ({
  type: "TOGGLE_CONFIDENCE_INTERVAL",
});

export const selectConfidenceInterval = (value) => ({
  type: "SELECT_CONFIDENCE_INTERVAL",
  value,
});

export const selectLogrankWeighting = (value) => ({
  type: "SELECT_LOGRANK_WEIGHTING",
  value,
});

export const selectSurvivalTabId = (id) => ({
  type: "SELECT_SURVIVAL_TAB_ID",
  id,
});

/* TASK */

export const setTimerId = (timerId) => ({
  type: "SET_TIMER_ID",
  timerId,
});

/* TOAST */

export const newToast = (toast) => ({
  type: "NEW_TOAST",
  toast,
});

export const removeToast = (id) => ({
  type: "REMOVE_TOAST",
  id,
});

export const clearToast = () => ({
  type: "CLEAR_TOAST",
});

/* TUTORIAL */

export const chooseTutorial = (tutorialIdx) => ({
  type: "CHOOSE_TUTORIAL",
  tutorialIdx,
});

export const startTutorial = (currentStep) => ({
  type: "START_TUTORIAL",
  currentStep,
});

export const stopTutorial = (restoreState) => ({
  type: "STOP_TUTORIAL",
  restoreState,
});

export const advanceTutorial = () => ({
  type: "ADVANCE_TUTORIAL",
});

export const recedeTutorial = () => ({
  type: "RECEDE_TUTORIAL",
});

export const resetTutorial = () => ({
  type: "RESET_TUTORIAL",
});

export const skipToEndOfTutorial = (lastStep) => ({
  type: "SKIP_TO_END_OF_TUTORIAL",
  lastStep,
});

export const toggleRestoreStateOnStop = () => ({
  type: "TOGGLE_RESTORE_STATE_ON_STOP",
});
