import React from "react";
import PropTypes from "prop-types";
import { Divider, Icon, Popover, Switch, Tooltip } from "@blueprintjs/core";
import LabelWithInfo from "../LabelWithInfo";
import TreatmentFilter from "../../containers/cohorts/TreatmentFilter";

const MappingPopover = ({
  id,
  treatmentTree,
  applyToPatients,
  useFixedSamples,
  hasFixedSamplesOption,
  isPopoverOpen,
  tooltip,
  toggleUseFixedSamples,
  toggleApplyToPatients,
  togglePopover,
}) => (
  <div className="mapping-popover-container">
    <Tooltip autoFocus="false" {...tooltip}>
      <Popover
        autoFocus={false}
        boundary="viewport"
        isOpen={isPopoverOpen}
        onInteraction={(nextState) =>
          nextState ? togglePopover(id) : togglePopover("")
        }
        position="auto-start"
        usePortal
      >
        <Icon className="icon-as-button" icon="prescription" small />
        <div className="sample-mapping-popover">
          {hasFixedSamplesOption ? (
            <div>
              <LabelWithInfo title="Use Fixed Samples" body={[]} />
              <Switch
                checked={useFixedSamples}
                label="Use Fixed Samples"
                onChange={() => toggleUseFixedSamples(id)}
              />
              <Divider />
            </div>
          ) : (
            <div />
          )}
          <div>
            <LabelWithInfo title="Treatment Filter" body={[]} />
            <TreatmentFilter
              id={id}
              tree={treatmentTree}
              disable={useFixedSamples}
            />
            <LabelWithInfo title="Apply To Patients" body={[]} />
            <Switch
              disabled={useFixedSamples}
              checked={applyToPatients}
              label="Apply To Patients"
              onChange={() => toggleApplyToPatients(id)}
            />
          </div>
        </div>
      </Popover>
    </Tooltip>
  </div>
);

MappingPopover.propTypes = {
  id: PropTypes.string.isRequired,
  treatmentTree: PropTypes.array.isRequired,
  applyToPatients: PropTypes.bool.isRequired,
  useFixedSamples: PropTypes.bool.isRequired,
  hasFixedSamplesOption: PropTypes.bool.isRequired,
  isPopoverOpen: PropTypes.bool.isRequired,
  tooltip: PropTypes.object.isRequired,
  toggleUseFixedSamples: PropTypes.func.isRequired,
  toggleApplyToPatients: PropTypes.func.isRequired,
  togglePopover: PropTypes.func.isRequired,
};

export default MappingPopover;
